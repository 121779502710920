<style lang="less">
@import "./ManageUnits";
</style>
<template>
  <div>
    <div class="search-con search-con-top">
      <div>
        <h3>所有课程</h3>
        <!--<div class="header-tools" v-if="privilege >= 2">-->
        <!--  <a @click="addUnit()"><Icon type="md-add" /> 新增课程</a>-->
        <!--</div>-->
      </div>
      <Divider class="top-divider"></Divider>
      <!-- 第一个选择器  选择学校  -->
      <Row class="search-filters">
        <label>
          <span>学校: </span>
          <Select
            v-model="campus"
            placeholder="请选择学校"
            style="width: 200px; margin-right: 20px"
          >
            <Option
              v-for="item in campusList"
              :value="item.value"
              :key="item.value"
            >
              {{ item.value }}
            </Option>
          </Select>
        </label>
        <!-- 第二个选择器  选择学院  -->
        <label>
          <span>学院: </span>
          <Select
            v-model="faculty"
            placeholder="请选择学院"
            @on-change="fetchUnits"
            style="width: 200px; margin-right: 20px"
          >
            <Option
              v-for="item in facultyList"
              :value="item.value"
              :key="item.value"
              >{{ item.value }}
            </Option>
          </Select>
        </label>
        <!-- 第三个选择器  选择开课学期 -->
        <label>
          <span>学期: </span>
          <Select
            v-model="semester"
            placeholder="选择开课学期"
            filterable
            style="width: 250px; margin-right: 20px"
          >
            <Option
              v-for="item in semesterList"
              :value="item.value"
              :key="item.value"
              >{{ item.label }}
            </Option>
          </Select>
        </label>
        <!-- 第四个选择器  选择科目代码 -->
        <label>
          <span>科目: </span>
          <Select
            v-model="selectedUnits"
            placeholder="选择科目代码"
            multiple
            @on-change="updateSelectedUnits"
            filterable
            style="width: 250px; margin-right: 20px"
          >
            <Option
              v-for="(item, index) in unitsList"
              :value="item.value"
              :key="item.value"
              :label="item.value"
            >
              <span>
                {{ item.value }}
              </span>
              <span style="float: right; color: #ccc; max-width: 200px">
                {{ unitInfoList[index] }}
              </span>
            </Option>
          </Select>
        </label>
        <!--   高级选择器  选择的课程类型 -->
        <Button type="primary" size="default" @click="handleShowAdvanced">
          {{ showAdvanced ? "收起" : "更多" }}
        </Button>
      </Row>
      <div v-show="showAdvanced">
        <br />
        <h3>
          按课程类型筛选
          <Checkbox
            :indeterminate="indeterminate"
            :value="checkAll"
            @click.prevent.native="handleCheckAllTypes"
            >所有类型
          </Checkbox>
        </h3>
        <br />
        <CheckboxGroup v-model="selectedTypes" @on-change="updateSelectedTypes">
          <Checkbox
            v-for="(type_name, type_code) in courseTypes"
            :label="type_code"
            :value="type_code"
            :key="type_code"
            border
            >{{ type_name }}</Checkbox
          >
        </CheckboxGroup>
      </div>
      <!--      <p>{{ selectedTypes }}</p>-->
    </div>
    <Table
      border
      :data="tableData"
      :columns="tableOrderColumns"
      :loading="loading"
      stripe
      style="margin-top: 20px"
    >
      <template slot-scope="{ row }" slot="status">
        <div>
          <Tag :color="semesterMap.get(row.semester) ? 'success' : 'default'">
            <strong>
              {{ semesterMap.get(row.semester) ? "已激活" : "未激活" }}
            </strong>
          </Tag>
        </div>
      </template>
      <template slot-scope="{ row }" slot="semester">
        <span
          class="text-main"
          :class="{ 'text-vice': !semesterMap.get(row.semester) }"
        >
          {{ row.semester }}
        </span>
      </template>
      <template slot-scope="{ row }" slot="action">
        <!-- <Button
          type="info"
          size="small"
          style="margin-right: 5px"
          @click="unitDetails(row)"
          >课程详情</Button
        >-->
        <Button
          type="primary"
          size="small"
          @click="modifyUnit(row)"
          style="margin: 5px"
          >修改课程</Button
        >
        <Button type="primary" size="small" @click="modifyVideo(row)"
          >课程详情</Button
        >
      </template>
    </Table>
    <div style="margin: 10px; overflow: hidden">
      <div style="display: flex; justify-content: space-between">
        <Button
          v-if="privilege >= 2"
          type="success"
          size="default"
          @click="addUnit()"
          ><Icon type="md-add" /> 新增课程
        </Button>
        <Page
          style="float: right"
          :total="totalCourseCount"
          :current.sync="currentPage"
          @on-change="changePage"
        ></Page>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      tableOrderColumns: [
        {
          title: "课程状态",
          slot: "status",
          width: 100,
          align: "center"
        },
        {
          title: "开课学期",
          slot: "semester",
          minWidth: 120
        },
        {
          title: "课程名称",
          key: "course_name",
          sortable: true,
          minWidth: 200
        },
        {
          title: "老师",
          key: "teacher",
          minWidth: 200
        },
        {
          title: "价格",
          key: "price",
          width: 225,
          render: (h, { row }) => {
            if (row.original_price !== row.price) {
              return (
                <span>
                  <Tag color="success" style="font-size:14px;">
                    现价: ${parseFloat(row.price).toFixed(2)}{" "}
                  </Tag>
                  <span style="fontSize: 12px; color: red">
                    <del>原价:${parseFloat(row.original_price).toFixed(2)}</del>
                  </span>
                </span>
              );
            } else {
              return (
                <span style="fontSize: 12px;">
                  <Tag color="success">
                    原价:${parseFloat(row.original_price).toFixed(2)}
                  </Tag>
                </span>
              );
            }
          }
        },
        {
          title: "管理该课程",
          slot: "action",
          width: 300,
          align: "center"
        }
      ],
      showAdvanced: false,
      checkAll: false,
      indeterminate: false,
      tableData: [],
      campusList: [],
      campus: "",
      facultyList: [],
      faculty: "",
      unitsList: [],
      unitInfoList: [],
      selectedUnits: [],
      courseTypes: [],
      selectedTypes: [],
      currentPage: 1,
      totalCourseCount: 0,
      semesterMap: new Map(),
      semesterList: [],
      semester:
        this.$route.query.semester == null
          ? "active"
          : this.$route.query.semester
    };
  },
  methods: {
    conductFetchCoursesParams() {
      let units = null;
      if (this.selectedUnits.length > 0) {
        units = this.selectedUnits.join(",");
      }
      let types = null;
      if (
        this.selectedTypes.length < Object.keys(this.courseTypes).length &&
        this.selectedTypes.length > 0
      ) {
        types = this.selectedTypes.join(",");
      }
      const page = this.currentPage;
      const semesterList = this.semesterList.map(obj => obj.value);
      let semester = this.semester;
      if (!semesterList.includes(semester)) semester = "active";
      if (semester === "active") semester = null;
      return {
        codes: units,
        page: page,
        types: types,
        semester: semester
      };
    },
    setupUnitList(rawUnitsList) {
      const pattern = /[a-zA-Z]+\d+/;
      // remove duplicated units
      const unitsSet = new Set();
      for (const course of rawUnitsList) {
        const match = pattern.exec(course);
        if (match != null) unitsSet.add(match[0]);
      }
      // set Unit list
      this.unitsList = [...unitsSet].map(course => ({
        value: course
      }));
      // clear selected units
      this.selectedUnits = [];
    },
    //在manageunit页面的科目菜单显示unit的信息：比如选项是FIT9131，则右边显示python
    setUpUnitcode(rawUnitinfo) {
      this.unitInfoList = rawUnitinfo;
    },
    fetchCourses() {
      this.loading = true;
      this.axios
        .get(`api/campus/${this.campus}/faculty/${this.faculty}/courses`, {
          params: this.conductFetchCoursesParams()
        })
        .then(resp => {
          const rawData = resp.data;
          this.totalCourseCount = rawData.total_items;
          this.tableData = rawData.items;
          this.loading = false;
        });
    },
    fetchUnits() {
      let semester = this.semester;
      const semesterList = this.semesterList.map(obj => obj.value);
      if (!semesterList.includes(semester)) semester = "active";
      if (semester === "active") semester = null;
      // alert(semester);
      this.axios
        .get(`api/campus/${this.campus}/faculty/${this.faculty}/units`, {
          params: {
            semester: semester
          }
        })
        .then(resp => {
          this.setUpUnitcode(Object.values(resp.data));
          //console.log(Object.values(resp.data));
          this.setupUnitList(Object.keys(resp.data));
          this.fetchCourses();
        });
    },
    fetchFaculties() {
      this.axios.get(`api/campus/${this.campus}/faculties`).then(resp => {
        const faculties = resp.data;
        this.facultyList = faculties.map(faculty => ({ value: faculty }));
        this.faculty = faculties[0];
        this.fetchUnits();
      });
    },
    updateSelectedUnits() {
      this.currentPage = 1;
      this.fetchCourses();
    },
    handleShowAdvanced() {
      this.selectedTypes = [];
      this.checkAll = false;
      this.indeterminate = false;
      this.showAdvanced = !this.showAdvanced;
      if (!this.showAdvanced) this.fetchCourses();
    },
    handleCheckAllTypes() {
      if (this.indeterminate) {
        this.checkAll = false;
      } else {
        this.checkAll = !this.checkAll;
      }
      this.indeterminate = false;

      if (this.checkAll) {
        this.selectedTypes = Object.keys(this.courseTypes);
        this.currentPage = 1;
        this.fetchCourses();
      } else {
        this.selectedTypes = [];
        this.tableData = [];
      }
    },
    updateSelectedTypes() {
      const data = this.selectedTypes;
      if (data.length === 5) {
        this.indeterminate = false;
        this.checkAll = true;
      } else if (data.length > 0) {
        this.indeterminate = true;
        this.checkAll = false;
      } else {
        this.indeterminate = false;
        this.checkAll = false;
      }
      this.currentPage = 1;
      this.fetchCourses();
    },
    changePage(currentPage) {
      this.currentPage = currentPage;
      this.fetchCourses();
    },
    modifyUnit(row) {
      const courseCode = row.course_code;
      this.$router.push({
        path: "/editunit",
        query: {
          id: courseCode
        }
      });
    },
    modifyVideo(row) {
      const courseCode = row.course_code;
      this.$router.push({
        path: "/unitInfo",
        query: {
          id: courseCode
        }
      });
    },
    addUnit() {
      this.$router.push("/addnewunit");
    }
  },
  computed: {
    privilege: function () {
      return this.$store.getters.getCurrentUser.privilege;
    },
    username: function () {
      return this.$store.getters.getCurrentUser.username;
    }
  },
  mounted: function () {
    // Handle url course types query
    if (this.$route.query.course_type != null) {
      this.selectedTypes = [this.$route.query.course_type];
      this.checkAll = false;
      this.indeterminate = false;
      this.showAdvanced = !this.showAdvanced;
    }
    this.axios.get("api/sessions").then(userInfo => {
      this.campus = userInfo.data.campus;
      this.campusList = [{ value: this.campus }];
      const promiseSem = this.axios.get(`api/campus/${this.campus}/semesters`);
      const promiseFac = this.axios.get(`api/campus/${this.campus}/faculties`);
      const promiseCourseType = this.axios.get(
        `api/campus/${this.campus}/course_types`
      );
      Promise.all([promiseSem, promiseFac, promiseCourseType])
        .then(responses => {
          // Deal with Semester data
          const semesterList = [
            { value: "active", label: "已激活学期" },
            { value: "all", label: "所有学期" }
          ];
          for (const entry of Object.entries(responses[0].data)) {
            this.semesterMap.set(entry[0], entry[1]);
          }
          this.semesterMap.forEach((_, k) =>
            semesterList.push({ value: k, label: k })
          );
          this.semesterList = semesterList;
          // Deal with faculties data
          const faculties = responses[1].data;
          this.facultyList = faculties.map(faculty => ({ value: faculty }));
          this.faculty = faculties[0];
          this.fetchUnits();
          this.courseTypes = responses[2].data;
        })
        .catch(err => {
          this.$Message.error(`获取信息失败！错误信息: ${err}`);
        });
    });
    this.$Message.config({
      top: 50,
      duration: 3
    });
  },
  watch: {
    semester: function () {
      this.currentPage = 1;
      this.fetchUnits();
      this.fetchCourses();
      // this.$router.query.semester = this.semester;
    }
  }
};
</script>
